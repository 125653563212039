<script>
import TarefaPageMixin from "components/suporte/components/task/TarefaPageMixin"

export default {
  name: 'SuporteTarefasRelatorios',
  mixins: [TarefaPageMixin],
  components: {},
  data() {
    return {
      routeName: 'suporte.tarefas.relatorios',
    }
  },
  mounted() {
  },
  computed: {},
  methods: {}
}
</script>

<template>
  <div v-if="$route.name === routeName">
    <div class="flex justify-between">
      <ul class="snav">
        <li><a @click="$router.push({name: 'suporte.dashboard'})">Suporte</a></li>
        <li><a @click="$router.push({name: 'suporte.projetos'})">Projetos</a></li>
        <li v-if="projeto.type" class="disable cursor-pointer">{{ projeto.type.name }}</li>
        <li><a @click="$router.push({name: 'suporte.projetos.projeto', params: {id: projeto.id}})">{{ projeto.name }}</a></li>
        <li class="disable cursor-pointer">Relatórios</li>
      </ul>
      <div>
      </div>
    </div>
    <div class="stitle">
      <h2 class="cursor-pointer">Relatórios de tarefas</h2>
      <div class="sopts">
      </div>
    </div>
    Em breve
  </div>
  <router-view v-else />
</template>
